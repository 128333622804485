// Variables
$font-size-base: 14px;
$line-height-computed: 1.4;
$lines-to-show: 2;

// Multiline text with ellipsis
.omit-mobile {
  // background: #eee;
  font-size: $font-size-base;
  height: $font-size-base * $line-height-computed * $lines-to-show + 10px;
  line-height: $line-height-computed;
  width: 100%;

  h4,
  h1,
  h2,
  h3 {
    visibility: visible;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
.omit-1 {
  width: 100%;

  small,
  a,
  h4,
  b {
    visibility: visible;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
.omit-3 {
  width: 100%;

  small,
  a,
  h4,
  b {
    visibility: visible;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
.omit-top-1 {
  width: 200px;

  small,
  a {
    visibility: visible;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
.omit-desktop {
  // background: #eee;
  overflow: hidden;
  width: 100%;
  p {
    visibility: visible;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
  }
}
