.magicode-ads {
  position: relative;
  border-radius: 8px;
  margin: 1rem 0rem;
  width: auto;
  height: auto;

  img {
    border-radius: 8px;
    margin: 0px;
    max-height: 300px;
    height: auto;
    width: auto;
  }
  .ads-tip {
    top: 3px;
    right: 3px;
    padding: 5px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.333);
    position: absolute;
    font-size: 8px;
    color: white;
  }
  .ads-icon {
    top: 3px;
    left: 3px;
    position: absolute;
  }
  .ads-open {
    padding: 4px 8px;
    position: absolute;
    display: flex;
    width: 100%;
    align-items: center;
    height: "30px";
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    bottom: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
