@import "variables";
.rst__rowToolbar {
  align-items: center;
}
.rst__moveHandle {
  @media (max-width: 900px) {
    display: none !important;
  }
}
.rst__rowContents {
  @media (max-width: 900px) {
    border-left: solid #bbb 1px !important;
  }
}
.box-image {
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  object-fit: cover;
  max-height: 250px;
  @media (max-width: 900px) {
    height: 150px;
  }
}
.box-image-card {
  border-radius: 10px;
  box-shadow: 0px 3px 6px -2px rgba(0, 10, 60, 0.2) !important;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  max-height: 260px;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  max-width: 260px;
  padding-bottom: 100%;
}
.box-sideimage {
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  object-fit: cover;
  height: 100%;
  max-height: 100px;
  @media (max-width: 900px) {
    height: 60px;
  }
}
.box-topimage {
  border-radius: 10px;
  object-fit: cover;
  height: 40px;
}

.MuiNodeContent-row {
  background-color: white;
  color: gray;
  border-bottom: 0.7px solid #f1f5f9;
  .node-title {
    color: gray;
  }
  &:hover {
    background-color: rgb(233, 233, 233);
    .node-title {
      color: $C-THEME !important;
    }
  }
}
.dark .MuiNodeContent-row {
  background-color: #121212 !important;
  b,
  a {
    color: white !important;
  }
  border-color: rgb(55, 65, 81) !important;
  border-bottom: 0.7px solid rgb(55, 65, 81) !important;
}
.my-button {
  display: flex;
  padding: 8px;
  padding-left: 20px;
  padding-right: 18px;
  align-items: center;
  color: gray;
  background-color: white;
  border-top: 2px solid #f1f5f9;
  cursor: pointer;
  &:hover {
    background-color: rgb(233, 233, 233);
    b {
      color: $C-THEME !important;
    }
  }
}
.dark .my-button {
  background-color: #121212 !important;

  color: white !important;
  border-color: rgb(55, 65, 81) !important;
  &:hover {
    background-color: #374151 !important;
  }
}
