/*

    Name:       dracula
    Author:     Michael Kaminsky (http://github.com/mkaminsky11)

    Original dracula color scheme by Zeno Rocha (https://github.com/zenorocha/dracula-theme)

*/

.cm-s-dracula.CodeMirror,
.cm-s-dracula .CodeMirror-gutters {
  background-color: #282a36 !important;
  color: #f8f8f2 !important;
  border: none;
}
.cm-s-dracula .CodeMirror-gutters {
  color: #282a36;
}
.cm-s-dracula .CodeMirror-cursor {
  border-left: solid thin #f8f8f0;
}
.cm-s-dracula .CodeMirror-linenumber {
  color: #6d8a88;
}
.cm-s-dracula .CodeMirror-selected {
  background: rgba(255, 255, 255, 0.1);
}
.cm-s-dracula .CodeMirror-line::selection,
.cm-s-dracula .CodeMirror-line > span::selection,
.cm-s-dracula .CodeMirror-line > span > span::selection {
  background: rgba(255, 255, 255, 0.1);
}
.cm-s-dracula .CodeMirror-line::-moz-selection,
.cm-s-dracula .CodeMirror-line > span::-moz-selection,
.cm-s-dracula .CodeMirror-line > span > span::-moz-selection {
  background: rgba(255, 255, 255, 0.1);
}
.cm-s-dracula span.cm-comment {
  color: #6a9955 !important;
}
.cm-s-dracula span.cm-string,
.cm-s-dracula span.cm-string-2 {
  color: #ce9178 !important;
}
.cm-s-dracula span.cm-number {
  color: #b5cea8 !important;
}
.cm-s-dracula span.cm-variable {
  color: #9cdcfe !important;
}
.cm-s-dracula span.cm-variable-2 {
  color: white !important;
}
.cm-s-dracula span.cm-def {
  color: #dcdcaa !important;
}
.cm-s-dracula span.cm-operator {
  color: #d4d4d4 !important;
}
.cm-s-dracula span.cm-keyword {
  color: #c586c0 !important;
}
.cm-s-dracula span.cm-atom {
  color: #bd93f9 !important;
}
.cm-s-dracula span.cm-meta {
  color: #f8f8f2 !important;
}
.cm-s-dracula span.cm-tag {
  color: #569cd6 !important;
}
.cm-s-dracula span.cm-attribute {
  color: #ce9178 !important;
}
.cm-s-dracula span.cm-qualifier {
  color: #50fa7b !important;
}
.cm-s-dracula span.cm-property {
  color: #dcdcaa !important;
}
.cm-s-dracula span.cm-builtin {
  color: #dcdcaa !important;
}
.cm-s-dracula span.cm-variable-3,
.cm-s-dracula span.cm-type {
  color: #ffb86c !important;
}

.cm-s-dracula .CodeMirror-activeline-background {
  background: rgba(255, 255, 255, 0.1);
}
.cm-s-dracula .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
