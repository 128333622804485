@import "variables";
html,
body {
  &::-webkit-scrollbar {
    display: contents !important;
    width: 6px !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(228, 228, 228, 0.1);
  }

  /*スクロールバーの動く部分*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(82, 82, 82, 0.3);
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(82, 82, 82, 0.3);
  }
  scrollbar-width: 6px !important;

  // overflow-y: auto;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;

  background-color: white;
  color: black;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
html:lang(ja),
body:lang(ja) {
  font-family: -apple-system, BlinkMacSystemFont, Hiragino Kaku Gothic ProN,
    Hiragino Sans, Meiryo, sans-serif, Segoe UI Emoji;
}
html:lang(en),
body:lang(en) {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
div {
  &::-webkit-scrollbar {
    display: none !important;
  }
}
.my-scrollbar {
  &::-webkit-scrollbar {
    display: contents !important;
    width: 6px !important;
    height: 6px !important;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(228, 228, 228, 0.1);
  }

  /*スクロールバーの動く部分*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(82, 82, 82, 0.3);
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(82, 82, 82, 0.3);
  }
}
html.dark,
html.dark body {
  background-color: #121212 !important;
  color: #c8d1d9 !important;
}
// ::-webkit-scrollbar {
//   display: none !important;
// }
.no-scroll-bar {
  &::-webkit-scrollbar {
    width: 0px !important;
  }

  scrollbar-width: 0px !important;
}
hr {
  display: block;
  unicode-bidi: isolate;
  margin: 1rem 0 !important;
  margin-block-start: 0.5em !important;
  margin-block-end: 0.5em !important;
  margin-inline-start: auto !important;
  margin-inline-end: auto !important;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
  color: inherit;
  &:not([size]) {
    height: 1px !important;
  }
}
iframe {
  max-width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}
a:hover {
  color: $C_THEME;
  text-decoration: none;
  svg,
  b {
    color: $C_THEME;
  }
  .MuiAvatar-root {
    b {
      color: white !important;
    }
  }
}
.dark a:hover {
  color: $C_THEME_DARKMODE !important;
  svg,
  b {
    color: $C_THEME_DARKMODE;
  }
}
.underlined a:hover,
.underlined a:focus,
.underlined a:active {
  text-decoration: underline;
}
svg {
  display: inherit;
}
b,
strong {
  font-weight: 600;
}
.row,
.col-md-1,
.col-md-3,
.col-md-6,
.col-md-7,
.col-md-10,
.col-md-12 {
  padding-right: 0rem;
  margin-right: 0rem;
}
img {
  max-width: 100%;
}
.page {
  word-wrap: break-word;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 90vh;
  max-width: 100%;
  box-sizing: border-box;
}
.MuiGrid-root {
  @media (max-width: 900px) {
    max-width: 100%;
    flex-grow: 1;
  }
}
.MuiGrid-item {
  padding: 1rem;
  @media (max-width: 900px) {
    padding: 0.5rem;
  }
}
.MuiCard-root {
  height: 100%;
  display: inline-block;
  // min-width: 200px;
  width: 100%;
  text-align: left;
  color: inherit;
  text-decoration: none;
}

.MuiCardHeader-title {
  font-size: 1.3rem !important;
  font-weight: 600 !important;
  overflow: hidden;
  width: 100%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.light-shadow {
  box-shadow: 0px 3px 6px -2px rgba(0, 10, 60, 0.2);
}
.dark .light-shadow {
  box-shadow: 0 -1.5px 8px rgba(0, 0, 0, 0.8) !important;
}
.light-shadow-hover {
  box-shadow: 0px 3px 6px -2px rgba(0, 10, 60, 0.2) !important;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
}
.dark .light-shadow-hover {
  box-shadow: 0 -1.5px 8px rgba(0, 0, 0, 0.8) !important;
  &:hover {
    box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.8) !important;
  }
}
.grow {
  flex-grow: 1;
  align-items: center;
}
.expand {
  transform: "rotate(0deg)";
  margin-left: "auto";
  transition: "100ms";
}
.expanded {
  transform: "rotate(180deg)";
  margin-left: "auto";
  transition: "100ms";
}
.hovering-button-white {
  background-color: white !important;

  &:hover {
    background-color: lightgray !important;
  }
}
.dark .hovering-button-white {
  background-color: rgb(87, 87, 87) !important;
  color: white !important;
  &:hover {
    background-color: rgb(41, 41, 41) !important;
  }
}
.hovering-button-orange {
  svg,
  span {
    color: white;
  }
  background-color: $C_THEME !important;
  &:hover {
    filter: brightness(85%) !important;
  }
}
.dark .hovering-button-orange {
  background-color: $C_THEME_DARKMODE !important;
}
.boxing {
  box-sizing: border-box;
  font-size: 13px;
  padding: 16px;
  border-radius: 2px;
  box-shadow: inset 0px 1px 8px -3px #ababab;
}
.tree {
  position: relative;
  max-height: 30vh;
  overflow: scroll;
  text-align: left;
}
.tree ul {
  padding-left: 5px;
  list-style: none;
}
.tree ul li {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.tree ul li:before {
  position: absolute;
  top: 15px;
  left: 0;
  width: 10px;
  height: 1px;
  margin: auto;
  content: "";
  background-color: #666;
}
.tree ul li:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 100%;
  content: "";
  background-color: #666;
}
.tree ul li:last-child:after {
  height: 15px;
}
.tree ul a {
  cursor: pointer;
}
.tree ul a:hover {
  text-decoration: none;
}
.noPadding {
  padding-top: 0px !important;
}
.MuiList-padding {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.option-box {
  padding-left: 0px;
  .MuiCard-root {
    cursor: pointer;
    border: 1px solid #ffffff;
    &:hover {
      // background-color: $C_BG;

      border: 1px solid $C_THEME;
    }
  }
}
.dark .option-box {
  .MuiCard-root {
    &:hover {
      border: 1px solid $C_THEME_DARKMODE !important;
      // background-color: gray !important;
    }
  }
}
.option-checked-box {
  padding-left: 0px;
  .MuiCard-root {
    cursor: pointer;
    // background-color: $C_BG;
    border: 1px solid $C_THEME;
  }
}
.dark .option-checked-box {
  .MuiCard-root {
    border: 1px solid $C_THEME_DARKMODE !important;
  }
}
.MuiToolbar-regular {
  min-height: 0em !important;
}
#nprogress .bar {
  background: $C-THEME;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}
.menu-item {
  display: flex !important;
  color: gray !important;
  vertical-align: middle !important;
  min-width: 180px !important;
  img {
    width: 30px;
    height: 30px;
    margin: 0.5rem;
  }
  svg {
    width: 30px;
    height: 30px;
    margin: 0.5rem;
    color: $C-THEME;
  }
}
.logo-color {
  img[src*="svg"] {
    color: $C-THEME;
  }
}
.MuiPagination-ul {
  justify-content: center !important;
}
.sticky-head {
  background-color: $C-THEME;
  border-bottom: 1.5px solid #f1f5f9;
  width: 520px !important;
  position: sticky;
  top: 0;
  z-index: 13;
  min-height: "48px";
  .head-label {
    position: relative;
    display: flex;
    vertical-align: middle;
    justify-content: center;
    color: white;
    h5 {
      margin: 0;
    }
    .icon-close {
      position: absolute;
      right: 5px;
      top: 0px;
      svg {
        color: white;
      }
    }
  }
}

//https://mui.com/components/progress/#ie-11
.MuiCircularProgress-indeterminate {
  animation: circular-rotate 1.4s linear infinite !important;
}

@keyframes circular-rotate {
  0% {
    transform: rotate(0deg) !important;
    /* Fix IE11 wobbly */
    transform-origin: 50% 50% !important;
  }
  100% {
    transform: rotate(360deg) !important;
  }
}

.MuiAppBar-root {
  // height: 60px !important;
  .MuiToolbar-root {
    height: 60px !important;
  }
}
.dark {
  .MuiInput-underline::before {
    border-bottom: 1px solid #ababab !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #ababab !important;
  }
  .MuiAutocomplete-root {
    .MuiChip-outlined {
      border-color: white !important;
    }
    .MuiChip-deleteIcon {
      color: white !important;
    }
  }
}
.MuiAutocomplete-root {
  .MuiInputBase-root {
    flex-wrap: wrap !important;
    align-items: center !important;
  }
  .MuiChip-outlined {
    margin-right: 6px !important;
    margin-bottom: 5px !important;
  }
  .MuiAutocomplete-clearIndicator {
    color: white;
  }
  textarea {
    width: auto !important;
  }
}
.cm-s-dracula .CodeMirror-linenumber {
  color: #ababab !important;
}
.juniper-cell hr {
  margin-top: 0px !important;
}
.CodeMirror-linenumber {
  text-align: center !important;
}
.CodeMirror {
  padding-bottom: 1.1rem;
}
.adsense {
  ins {
    width: 100% !important;
    margin-left: 0 !important;
  }
}
.tab-many-string {
  @media (max-width: 480px) {
    font-size: 12px;
  }
}
.admax-switch {
  div {
    transform: none !important;
  }
}
.MuiDrawer-paper {
  @media (max-width: 900px) {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
}
