@import "variables";
.about {
  h1 {
    b {
      font-weight: 650;
      // @media (max-width: 480px) {
      //   font-weight: 550;
      // }
    }
  }
}
.service-intro {
  position: relative;
  z-index: 1;
  h1 {
    font-size: 80px;
    @media (max-width: 480px) {
      font-size: 56px;
    }
  }
}
.ads-service-intro {
  position: relative;
  z-index: 1;
  h1 {
    font-size: 60px;
    @media (max-width: 480px) {
      font-size: 50px;
    }
  }
}
.service-features {
  h1 {
    font-size: 50px;
    @media (max-width: 480px) {
      font-size: 36px;
    }
  }
}
.service-medium {
  h1 {
    font-size: 40px;
    @media (max-width: 480px) {
      font-size: 32px;
    }
  }
}

// animated background
.area {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 50%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 5%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}
.hexagons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  li {
    z-index: 0;
    position: absolute;
    bottom: -150px;
    width: 100px;
    height: 57.54px;
    margin: 28.87px 0;
    display: block;
    list-style: none;
    background: rgba(255, 102, 0, 0.294);
    animation: animate 25s linear infinite;
    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 0;
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
    }
    &::before {
      bottom: 100%;
      border-bottom: 28.87px solid rgba(255, 102, 0, 0.294);
    }
    &::after {
      top: 100%;
      width: 0;
      border-top: 28.87px solid rgba(255, 102, 0, 0.294);
    }
  }
}
.dark .hexagons {
  li {
    background: rgba(255, 255, 255, 0.2);
    &::before {
      border-bottom: 28.87px solid rgba(255, 255, 255, 0.2);
    }
    &::after {
      border-top: 28.87px solid rgba(255, 255, 255, 0.2);
    }
  }
}
.hexagons li:nth-child(1) {
  left: 25%;
  animation-delay: 0s;
}

.hexagons li:nth-child(2) {
  left: 10%;
  animation-delay: 2s;
  animation-duration: 12s;
}

.hexagons li:nth-child(3) {
  left: 70%;
  animation-delay: 4s;
}

.hexagons li:nth-child(4) {
  left: 40%;
  animation-delay: 0s;
  animation-duration: 18s;
}

.hexagons li:nth-child(5) {
  left: 65%;
  animation-delay: 0s;
}

.hexagons li:nth-child(6) {
  left: 50%;
  animation-delay: 3s;
}

.hexagons li:nth-child(7) {
  left: 85%;
  animation-delay: 7s;
}

.hexagons li:nth-child(8) {
  left: 30%;
  animation-delay: 15s;
  animation-duration: 45s;
}

.hexagons li:nth-child(9) {
  left: 20%;
  animation-delay: 2s;
  animation-duration: 35s;
}

.hexagons li:nth-child(10) {
  left: 5%;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
  }
}
.top-page-magicode {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
  font-size: 16px;
  line-height: 1.9;
  word-wrap: break-word;
  width: 100%;
  overflow-x: auto;
  color: white;
  .MuiTabs-root {
    border-radius: 8px 8px 0 0;
    background-color: rgb(40, 42, 54);
    color: white;
  }
  .MuiTab-root {
    background-color: rgb(40, 42, 54);
    color: white;
  }
  .juniper-cell {
    position: relative;
    background-color: rgb(40, 42, 54);
    border-radius: 0 0 8px 8px;
    .code-copy {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      z-index: 12;
      svg {
        color: white;
        opacity: 0;
      }
    }
    &:hover {
      .code-copy {
        svg {
          opacity: 0.6;
        }
      }
    }
    hr {
      color: white;
    }
    .CodeMirror {
      line-height: 1.5;
      height: auto !important;
      .CodeMirror-line {
        overflow-wrap: break-word;
        white-space: inherit;
      }
      .CodeMirror-scroll {
        background-color: rgb(40, 42, 54);
        padding: 10px;
        padding-bottom: 40px;
      }
      .CodeMirror-selected {
        background: gray !important;
      }
    }
    .magicode-button {
      margin: 10px;
      cursor: pointer;
      width: 24px;
      height: 24px;
      position: relative;
    }
    .juniper-input pre,
    .juniper-output {
      &::-webkit-scrollbar {
        display: none !important;
      }
      font-variant-ligatures: none !important;
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto;
      background-color: rgb(40, 42, 54);
      padding: 8px;
      // width: 100%;
      border-radius: 0 0 8px 8px;
      font-size: 13px;
      // overflow-x: auto;
      overflow-wrap: break-word;
      .p-Widget {
        pre {
          background-color: inherit;
          color: white;
          // white-space: pre;
        }
      }
    }
    .juniper-output {
      max-height: 60vh;
      overflow-y: auto;
    }
    .jp-Stdin-input {
      background-color: white !important;
      color: black !important;
    }
  }
}
