@import "variables";

// Override Syntax Highlighting In Minima With the Dracula Theme: https://draculatheme.com/
// If you wish to override any of this CSS, do so in _sass/minima/custom-styles.css

$dt-gray-dark: #282a36; // Background
$dt-code-cell-background: #323443;
$dt-gray: #44475a; // Current Line & Selection
$dt-gray-light: #f8f8f2; // Foreground
$dt-blue: #6272a4; // Comment
$dt-cyan: #8be9fd;
$dt-green: #50fa7b;
$dt-orange: #ffb86c;
$dt-pink: #ff79c6;
$dt-purple: #bd93f9;
$dt-red: #ff5555;
$dt-yellow: #f1fa8c;
$dt-green-light: rgb(172, 229, 145);
$dt-output: #f1f5f9;

.jp-CodeCell table {
  width: auto;
}

.highlight {
  word-break: keep-all !important;
  background: $dt-code-cell-background !important;
  color: $dt-gray-light !important;
  line-height: 1.4 !important;
  padding: 1rem;
  word-wrap: normal !important;

  pre,
  code {
    background: $dt-code-cell-background !important;
    color: $dt-gray-light !important;
    font-size: 105%;
  }

  .hll,
  .s,
  .sa,
  .sb,
  .sc,
  .dl,
  .sd,
  .s2,
  .se,
  .sh,
  .si,
  .sx,
  .sr,
  .s1,
  .ss {
    color: rgb(231, 153, 122) !important;
  }

  .go {
    color: $dt-gray;
  }

  .err,
  .g,
  .l,
  .n,
  .x,
  .ge,
  .gr,
  .gh,
  .gi,
  .gp,
  .gs,
  .gu,
  .gt,
  .ld,
  .no,
  .nd,
  .pi,
  .ni,
  .ne,
  .nn,
  .nx,
  .py,
  .w,
  .bp {
    color: $dt-gray-light;
    background-color: $dt-code-cell-background !important;
  }

  .p {
    font-weight: bold;
    color: rgb(102, 217, 239);
  }

  .ge {
    text-decoration: underline;
  }

  .bp {
    font-style: italic;
  }

  .c,
  .ch,
  .cm,
  .cpf,
  .cs {
    color: $dt-blue;
  }

  .c1 {
    color: gray;
  }

  .kd,
  .kt,
  .nb,
  .nl,
  .nv,
  .vc,
  .vg,
  .vi,
  .vm {
    color: $dt-cyan;
  }

  .kd,
  .nb,
  .nl,
  .nv,
  .vc,
  .vg,
  .vi,
  .vm {
    font-style: italic;
  }

  .fm,
  .na,
  .nc,
  .nf {
    color: $dt-green-light;
  }

  .k,
  .o,
  .cp,
  .kc,
  .kn,
  .kp,
  .kr,
  .nt,
  .ow {
    color: $dt-pink;
  }

  .kc {
    color: $dt-green-light;
  }

  .m,
  .mb,
  .mf,
  .mh,
  .mi,
  .mo,
  .il {
    color: $dt-purple;
  }

  .gd {
    color: $dt-red;
  }
}

p {
  font-size: 16px;
}

.toc {
  &::-webkit-scrollbar {
    display: contents !important;
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(228, 228, 228, 0.1);
  }

  /*スクロールバーの動く部分*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(82, 82, 82, 0.3);
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(82, 82, 82, 0.3);
  }
  overflow-y: auto;
  max-height: 40vh;
  @media (max-width: 900px) {
    max-height: 80vh;
  }
  ul {
    list-style: none;
    padding-left: 0;
    .toc_h2 {
      padding-left: 1rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      border-left: 3px solid rgb(214, 214, 214);
    }
    .toc_h3 {
      padding-left: 2rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      border-left: 3px solid rgb(214, 214, 214);
    }
    .toc_h4 {
      padding-left: 3rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      border-left: 3px solid rgb(214, 214, 214);
    }
  }
  .active {
    color: $C_THEME;
    border-color: $C_THEME !important;
  }
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  li {
    &:hover {
      color: $C_THEME;
    }
    cursor: pointer;
  }
}
.editor .toc {
  max-height: 80vh;
}
.dark .toc {
  .active {
    color: $C_THEME_DARKMODE;
    border-color: $C_THEME_DARKMODE !important;
  }

  li {
    &:hover {
      color: $C_THEME_DARKMODE;
    }
  }
}
.toc-noteinbox {
  overflow-y: auto;
  max-height: 50vh;
  &::-webkit-scrollbar {
    display: contents !important;
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(228, 228, 228, 0.1);
  }

  /*スクロールバーの動く部分*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(82, 82, 82, 0.3);
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(82, 82, 82, 0.3);
  }
  ul {
    list-style: none;
    padding-left: 0;
    .toc_h2 {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
    .toc_h3 {
      padding-left: 1rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
    .toc_h4 {
      padding-left: 2rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }
  .active {
    color: $C_THEME;
  }
  font-size: 13px;
  font-weight: 600;
  line-height: 1.4;
  cursor: text;
  padding: 0px 16px 0px 16px;
  border-radius: 2px;
  li {
    &:hover {
      color: $C_THEME;
    }
    cursor: pointer;
  }
}
.dark .toc-noteinbox {
  .active {
    color: $C_THEME_DARKMODE;
  }

  li {
    &:hover {
      color: $C_THEME_DARKMODE;
    }
  }
}

.learn-action {
  color: gray;
  margin-bottom: 1rem;
  text-align: center;
  svg {
    width: 26px;
    height: 26px;
  }
  span {
    padding-left: 0.75rem;
  }
  .action {
    padding: 6px;
  }
  button {
    width: 180px !important;
    padding: 5px;
  }
}
.edit {
  height: 75vh;
  @media (max-width: 900px) {
    height: 60vh !important;
  }
  overflow-y: auto;
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.2);
  border: 0px !important;
  padding: 0.5em;
}
.dark .edit {
  box-shadow: 0px 0px 0px 0px rgba(165, 166, 173, 0.2) !important;
  border: 1px solid rgba(255, 255, 255, 0.63) !important;
}

.CodeMirror-hscrollbar {
  display: none !important;
}
#go-to-comment {
  &::-webkit-scrollbar {
    display: contents !important;
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(228, 228, 228, 0.1);
  }

  /*スクロールバーの動く部分*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(82, 82, 82, 0.3);
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgba(82, 82, 82, 0.3);
  }
}
.article-page {
  margin-bottom: 3rem;
  .code-cell {
    margin-top: 1.8rem;
    margin-bottom: 3rem;
  }
  img {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
  }
}
.copy-parent {
  position: relative;
  .code-copy {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    z-index: 12;
    svg {
      color: white;
      opacity: 0;
    }
  }
  &:hover {
    .code-copy {
      svg {
        opacity: 0.6;
      }
    }
  }
}
.markdown-cell body,
.article-page,
.article-page body {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    display: none !important;
  }
  font-size: 16px;
  line-height: 1.6;
  word-wrap: break-word;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  @media (max-width: 900px) {
    padding: 0.15rem;
  }
  background-color: inherit !important;
  .katex-display {
    margin: 1.5rem 0;
    .katex {
      .katex-html {
        overflow-x: auto;
        overflow-y: hidden;
        .tag {
          position: initial !important;
          margin-left: 2rem;
        }
      }
    }
  }

  .katex-error {
    color: inherit !important;
  }
  .anchor-link {
    display: none;
  }
  .MathJax_CHTML {
    overflow-x: auto;
    max-width: 100%;
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  code {
    border: 0px;
    border-radius: 3px;
  }

  .add-cell-button-area {
    margin: 0.5rem 0;
    height: 40px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    position: relative;
  }

  .add-cell-button-area:hover hr,
  .add-cell-button-area:hover .add-cell-button {
    opacity: 1;
  }

  .add-cell-button-area hr {
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
  }

  .add-cell-button {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    min-width: 400px;
  }

  .juniper-cell {
    position: relative;
    background-color: rgb(40, 42, 54);
    border-radius: 8px;
    color: rgb(236, 236, 236);
    margin: 1.5rem 0;
    &::-webkit-scrollbar {
      display: none !important;
    }

    .code-copy {
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
      z-index: 12;
      svg {
        color: white;
        opacity: 0;
      }
    }
    &:hover {
      .code-copy {
        svg {
          opacity: 0.6;
        }
      }
    }
    hr {
      color: rgb(236, 236, 236);
    }
    .CodeMirror {
      line-height: 1.5;
      border-radius: 8px;
      height: auto !important;
      z-index: 1;
      &::-webkit-scrollbar {
        display: none !important;
      }

      .CodeMirror-scroll {
        background-color: rgb(40, 42, 54);
        padding: 10px;
        border-radius: 8px;
        padding-bottom: 40px;
      }
      .CodeMirror-selected {
        background: gray !important;
      }
    }
    .code-header {
      .magicode-button {
        margin: 10px;
        cursor: pointer;
        width: 24px;
        height: 24px;
        position: relative;
      }
    }
    .juniper-input pre,
    .juniper-output {
      font-variant-ligatures: none !important;
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto;
      background-color: rgb(40, 42, 54);
      border-radius: 8px;
      padding: 0px 8px;
      width: 100%;
      overflow-x: auto;
      font-size: 13px;
      max-height: 60vh;
      overflow-y: auto;
      white-space: pre;
      &::-webkit-scrollbar {
        display: contents !important;
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(228, 228, 228, 0.1);
      }

      /*スクロールバーの動く部分*/
      &::-webkit-scrollbar-thumb {
        background-color: rgba(82, 82, 82, 0.3);
        border-radius: 10px;
        box-shadow: 0 0 0 1px rgba(82, 82, 82, 0.3);
      }
      .p-Widget {
        pre {
          background-color: inherit;
          color: rgb(236, 236, 236);
          white-space: pre;
        }
      }
      .jp-Stdin-input {
        background-color: rgb(236, 236, 236) !important;
        color: black !important;
      }
    }
  }
  div {
    &::-webkit-scrollbar {
      display: contents !important;
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(228, 228, 228, 0.1);
    }

    /*スクロールバーの動く部分*/
    &::-webkit-scrollbar-thumb {
      background-color: rgba(82, 82, 82, 0.3);
      border-radius: 10px;
      box-shadow: 0 0 0 1px rgba(82, 82, 82, 0.3);
    }
  }
  pre {
    word-wrap: none !important;
    overflow-x: auto;
    width: 100%;
    background-color: rgb(40, 42, 54);
    border-radius: 7px;
    position: relative;
    margin-bottom: 10px;
    padding-left: 0.5rem;
    // max-height: 70vh;
    // overflow-y: auto;
    margin: 1.5rem 0px;
    &::-webkit-scrollbar {
      display: contents !important;
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(228, 228, 228, 0.1);
    }

    /*スクロールバーの動く部分*/
    &::-webkit-scrollbar-thumb {
      background-color: rgba(82, 82, 82, 0.3);
      border-radius: 10px;
      box-shadow: 0 0 0 1px rgba(82, 82, 82, 0.3);
    }
    code {
      color: rgb(236, 236, 236);
      font-size: 0.9em;
      &[class*=":"] {
        margin-top: 2rem !important;
      }
      &[data-filename] {
        // ファイル名を表示する時だけ上に余白をあける
        padding-top: 1.3rem;
        // 疑似要素にファイル名を表示
        &::before {
          content: attr(data-filename);
          position: absolute;
          top: 0;
          padding: 0 0.3em;
          color: black;
          font-size: 0.6em;
          background-color: rgba(#fff, 0.8);
          border-bottom-left-radius: 2px;
        }
      }
    }
  }

  table {
    font-size: 14px;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    border: 0px;
    cursor: text;
    border-radius: 2px;
    margin-bottom: 2em;
    background: #ffffff;
    min-width: 100%;
    color: rgb(31, 31, 31);
    thead {
      th {
        position: sticky;
        top: 0;
        background-color: rgba(222, 223, 224, 0.8);
        border-top: 1px solid rgba(222, 223, 224, 0.8);
      }
    }
  }
  table th,
  table td {
    text-align: left !important;
    border: 1px solid rgb(222, 223, 224);
    padding: 3px;
  }
  tbody {
    tr {
      &:hover {
        td {
          background-color: $C_BG !important;
        }
      }
    }

    tr th {
      background-color: rgba(222, 223, 224, 0.8);
    }
  }

  a {
    color: $C_THEME;
    text-decoration: underline;
  }
  p {
    margin-top: 0.7rem;
    margin-bottom: 1.6px;
  }
  h2 {
    padding-top: 2rem;
    font-weight: 700;
    // border-bottom: 2px solid $dt-output;
    padding-bottom: 0.2rem;
  }
  h3 {
    padding-top: 2rem;
    // border-bottom: 1px solid $dt-output;
    padding-bottom: 0.2rem;
  }
  h4,
  h5 {
    padding-top: 1.8rem;
  }
  img {
    max-width: 100%;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
    padding: 8px !important;
  }
  li {
    margin-left: 1.3rem;
  }
  blockquote {
    margin: 0.7rem 0px;
    border-left: 3px solid rgb(214, 214, 214);
    margin-left: 0.5rem !important;
    font-size: 16px;
    padding: 0px 8px;
    color: gray;
    div {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }
  .MJX_Assistive_MathML {
    display: none;
  }
  iframe {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  details {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    summary {
      cursor: pointer;
    }
  }
}
.dark {
  .markdown-cell body,
  .article-page,
  .article-page body {
    color: #d5dde5 !important;
  }
}
.mde-textarea-wrapper {
  textarea {
    min-height: 70vh !important;
    @media (max-width: 900px) {
      min-height: 55vh !important;
    }
  }
}
.dark .md-editor {
  box-shadow: 0px 0px 0px 0px rgba(165, 166, 173, 0.2) !important;
  border: 1px solid rgba(255, 255, 255, 0.63) !important;
}
.md-editor {
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.2);
  border: 0px !important;

  width: 100%;
  overflow-y: auto !important;
  height: 75vh !important;

  @media (max-width: 900px) {
    height: 60vh !important;
  }
  fieldset {
    border-width: 0px !important;
  }
}
.mde-header {
  flex-wrap: nowrap !important;
  width: 100%;
  overflow: auto;
  .mde-tabs {
    button {
      display: none;
    }
  }
}
.react-mde {
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.2);
  border: 0px !important;
  border-radius: 10px !important;
}
.link-card {
  color: inherit !important;
  border: 0.7px solid rgb(199, 199, 199);
  box-shadow: 0px 3px 6px -2px rgba(0, 10, 60, 0.2) !important;
  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
  padding: 2px;
  img {
    border-right: 0.7px solid rgb(199, 199, 199);
    width: 21px;
    height: 21px;
    padding: 3px;
  }
}
