.danger-zone {
  border-radius: 8px;
  border: 0.5px solid red;
}
.MuiDialog-root {
  .MuiPaper-root {
    .danger {
      b {
        color: red !important;
      }
    }
    .disabled {
      b {
        color: gray !important;
      }
    }
  }
}
